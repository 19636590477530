import {createSlice} from '@reduxjs/toolkit';
import {HYDRATE} from 'next-redux-wrapper';

const initialState = {
  value: {}
};

export const rateSlice = createSlice({
  name: 'rate',
  initialState,
  reducers: {
    set: (state, action) =>
    {
      state.value[action.payload.chain_id] = action.payload.value;
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) =>
    {
      return {
        ...state,
        ...action.payload.rate
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const {set} = rateSlice.actions;

export default rateSlice.reducer;