const footerMenuList = [
  {
    id: 1,
    title: 'About',
    diffClass: 'md:col-start-7',
    list: [
      {
        id: 1,
        href: 'https://m3.games',
        text: 'What\'s M3'
      },
      {
        id: 2,
        href: 'https://m3.games/files_whitepapper/MMM-Whitepaper.pdf',
        text: 'Whitepaper'
      }
    ]
  },
  {
    id: 2,
    title: 'Contact',
    diffClass: '',
    list: [
      {
        id: 1,
        href: 'mailto:support@multimetamultiverse.com',
        text: 'Contact us'
      }
    ]
  },
  {
    id: 3,
    title: 'Play',
    diffClass: '',
    list: [
      {
        id: 1,
        href: 'https://game-sweet.sweetclash.io/sweetclash/reg?tpl=landing_1&sp=777&ss=m3game&ts=menu&version=Edenred',
        text: 'Play now'
      }
    ]
  }
];

const socialIcons = [
  {
    id: 1,
    href: 'https://www.facebook.com/M3.games',
    text: 'facebook'
  },
  {
    id: 2,
    href: 'https://twitter.com/M3gamesofficial',
    text: 'twitter'
  },
  {
    id: 3,
    href: 'https://discord.gg/kXpkkBcVeZ',
    text: 'discord'
  },
  {
    id: 4,
    href: 'https://www.instagram.com/m3.games/',
    text: 'instagram'
  },
  {
    id: 5,
    href: 'https://t.me/M3_games_english',
    text: 'telegram'
  }
];

export {footerMenuList, socialIcons};
