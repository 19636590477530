import {createSlice} from '@reduxjs/toolkit';
import {HYDRATE} from 'next-redux-wrapper';

const initialState = {
  value: {}
};

export const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    set: (state, action) =>
    {
      state.value = action.payload;
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) =>
    {
      return {
        ...state,
        ...action.payload.progress
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const {set} = progressSlice.actions;

export default progressSlice.reducer;