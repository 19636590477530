import {createSlice} from '@reduxjs/toolkit';
import {HYDRATE} from 'next-redux-wrapper';

const initialState = {
  mobile_menu: false,
  dropdown: false,
  buyModal: false,
  withdrawModal: false,
  networkModal: false,
  successModal: false
};

export const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    openMobileMenu: (state) =>
    {
      state.mobile_menu = true;
    },
    closeMobileMenu: (state) =>
    {
      state.mobile_menu = false;
    },
    openDropdown: (state) =>
    {
      state.dropdown = true;
    },
    closeDropdown: (state) =>
    {
      state.dropdown = false;
    },
    buyModalShow: (state) =>
    {
      state.buyModal = true;
    },
    buyModalHide: (state) =>
    {
      state.buyModal = false;
    },
    withdrawModalShow: (state) =>
    {
      state.withdrawModal = true;
    },
    withdrawModalHide: (state) =>
    {
      state.withdrawModal = false;
    },
    networkModalShow: (state) =>
    {
      state.networkModal = true;
    },
    networkModalHide: (state) =>
    {
      state.networkModal = false;
    },
    successModalShow: (state) =>
    {
      state.successModal = true;
    },
    successModalHide: (state) =>
    {
      state.successModal = false;
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) =>
    {
      return {
        ...state,
        ...action.payload.counter
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  openMobileMenu,
  closeMobileMenu,
  buyModalShow,
  buyModalHide,
  withdrawModalShow,
  withdrawModalHide,
  networkModalShow,
  networkModalHide,
  successModalShow,
  successModalHide
} = counterSlice.actions;

export default counterSlice.reducer;