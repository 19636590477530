import {configureStore} from '@reduxjs/toolkit';
import {createWrapper} from 'next-redux-wrapper';

import counterReducer from './counterSlice';
import profileReducer from './profileSlice';
import progressSlice from './progressSlice';
import rateSlice from './rateSlice';
import chainSlice from './chainSlice';

const makeStore = () => configureStore({
  reducer: {
    counter: counterReducer,
    profile: profileReducer,
    progress: progressSlice,
    rate: rateSlice,
    chain: chainSlice
  },
  devTools: true
});

// export an assembled wrapper
export const wrapper = createWrapper(makeStore);