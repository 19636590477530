import {createSlice} from '@reduxjs/toolkit';
import {HYDRATE} from 'next-redux-wrapper';

const initialState = {
  value: null
};

export const chainSlice = createSlice({
  name: 'chain',
  initialState,
  reducers: {
    set: (state, action) =>
    {
      state.value = action.payload;
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) =>
    {
      return {
        ...state,
        ...action.payload.chain
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const {set} = chainSlice.actions;

export default chainSlice.reducer;