const GET = (url, params = {}, headers = {}, base_url = process.env.NEXT_PUBLIC_DOMAIN_BACK, skip_codes = false) =>
{
  return query('GET', base_url + url, params, headers, skip_codes);
};

const POST = (url, params = {}, headers = {}, base_url = process.env.NEXT_PUBLIC_DOMAIN_BACK, skip_codes = false) =>
{
  return query('POST', base_url + url, params, headers, skip_codes);
};

const PUT = (url, params = {}, headers = {}, base_url = process.env.NEXT_PUBLIC_DOMAIN_BACK, skip_codes = false) =>
{
  return query('PUT', base_url + url, params, headers, skip_codes);
};

const PATCH = (url, params = {}, headers = {}, base_url = process.env.NEXT_PUBLIC_DOMAIN_BACK, skip_codes = false) =>
{
  return query('PATCH', base_url + url, params, headers, skip_codes);
};

const DELETE = (url, params = {}, headers = {}, base_url = process.env.NEXT_PUBLIC_DOMAIN_BACK, skip_codes = false) =>
{
  return query('DELETE', base_url + url, params, headers, skip_codes);
};

/**
 * @param {String} method
 * @param {String} url
 * @param {Object} params
 * @param {Object} headers
 * @param {Boolean} skip_codes
 * @return {Promise<Response>}
 */
const query = (method, url, params = {}, headers = {}, skip_codes = false) =>
{
  let query_options = {method, credentials: 'include'};

  if (Object.keys(headers).length)
  {
    query_options['headers'] = new Headers(headers);
  }

  if (method === 'GET')
  {
    if (Object.keys(params).length > 0)
    {
      url += `?${new URLSearchParams(params).toString()}`;
    }
  }
  else if (!(params instanceof FormData))
  {
    let data = new FormData();

    Object.keys(params).map((param_name) =>
    {
      if (typeof params[param_name] === 'object')
      {
        Object.values(params[param_name]).map((item) =>
        {
          data.append(param_name + '[]', item ? item.toString() : '')
        });
      }
      else
      {
        data.append(param_name, params[param_name] ? params[param_name].toString() : '')
      }
    });

    query_options['body'] = data;
  }
  else
  {
    query_options['body'] = params;
  }

  return fetch(url, query_options)
    .then(response =>
    {
      if (typeof window !== 'undefined')
      {
        if (response.status === 403 && !skip_codes)
        {
          window.location.href = '/';
        }
      }

      const contentType = response.headers.get('content-type');

      if (contentType && contentType.indexOf('application/json') !== -1)
      {
        return response.json().then((body) => ({status: response.status, body}));
      }

      return response.text().then((body) => ({status: response.status, body}));
    });
};

export {query, GET, POST, PATCH, PUT, DELETE};