import React from 'react';

import {useSelector} from 'react-redux';

const BuyModal = () =>
{
  const buyModal = useSelector((state) => state.counter.buyModal);

  return (
    <div className="relative z-100">
      <div className={buyModal ? 'block modal fade show ' : 'modal fade hidden'}>
        <div className="modal-dialog max-w-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title w-full text-center">Transaction is being processed</h5>
            </div>

            <div className="modal-body p-6 text-center">
              <svg className="icon icon-metamask mb-4 inline-block h-8 w-8">
                <use xlinkHref="/icons.svg#icon-metamask"></use>
              </svg>

              <p className="normal-case text-center dark:text-white">
                Please wait, the transaction is being processed, it may take some time
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyModal;