import React, {useState} from 'react';

import {useMetaMask} from 'metamask-react';

import {useSelector, useDispatch} from 'react-redux';
import {set as setProgress} from '../../redux/progressSlice';
import {withdrawModalHide} from '../../redux/counterSlice';

import * as Contract from '../../helpers/contract';

const WithdrawModal = () =>
{
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);

  const {account, chainId} = useMetaMask();
  const dispatch = useDispatch();

  const withdrawModal = useSelector((state) => state.counter.withdrawModal);
  const profile = useSelector((state) => state.profile.data);

  const submit = async (e) =>
  {
    e.preventDefault();

    const data = new FormData(e.target);
    const [provider, contract] = Contract.get(account, chainId);

    try
    {
      const transaction = await contract.releaseSavings(
        account,
        data.get('withdraw_address'),
        data.get('withdraw_amount'),
        {gasLimit: 500000}
      );

      lock();
      await transaction.wait();

      unlock();
      dispatch(withdrawModalHide());
    }
    catch (error)
    {
      console.dir(error);

      switch (error.reason)
      {
        case 'transaction failed':
          setError(true);
          unlock();
          break;
      }
    }
  }

  const lock = () =>
  {
    setError(false);
    dispatch(setProgress(20));
    setDisabled(true);
  }

  const unlock = () =>
  {
    dispatch(setProgress(100));
    setDisabled(false);
  }

  return (
    <div className="relative z-100">
      <div className={withdrawModal ? 'block modal fade show ' : 'modal fade hidden'}>
        <div className="modal-dialog max-w-lg w-full">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title w-full">Withdraw savings</h5>
              <button type="button" className="btn-close" onClick={() => dispatch(withdrawModalHide())}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            <form className="modal-body p-6" onSubmit={submit}>
              <div className="mb-6">
                <label htmlFor="withdraw-amount" className="font-display text-jacarta-700 mb-2 block dark:text-white">
                  Amount <span className="text-red">*</span>
                </label>

                <p className="normal-case dark:text-jacarta-300 text-2xs mb-3">Amount of savings to release</p>

                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max={profile.balance}
                  name="withdraw_amount"
                  id="withdraw-amount"
                  className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                  required
                />
              </div>

              <div className="mb-6">
                <label htmlFor="withdraw-address" className="font-display text-jacarta-700 mb-2 block dark:text-white">
                  Address <span className="text-red">*</span>
                </label>

                <input
                  type="text"
                  name="withdraw_address"
                  id="withdraw-address"
                  className="normal-case dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                  required
                />
              </div>

              <button disabled={disabled} className="bg-accent-lighter rounded-full py-3 px-8 text-center font-semibold text-white transition-all hover:bg-accent-dark">Submit</button>

              {error ?
                <p className="dark:text-jacarta-300 text-jacarta-400 text-sm mt-4">Transaction has been failed</p>
                : ''}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawModal;