import React from 'react';

import {useSelector} from 'react-redux';

const NetworkModal = () =>
{
  const networkModal = useSelector((state) => state.counter.networkModal);
  const chain = useSelector((state) => state.chain.value);

  return (
    <div className="relative z-100">
      <div className={networkModal ? 'block modal fade show ' : 'modal fade hidden'}>
        <div className="modal-dialog max-w-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title w-full text-center">Not supported network</h5>
            </div>

            <div className="modal-body p-6 text-center">
              <svg className="icon icon-metamask mb-4 inline-block h-8 w-8">
                <use xlinkHref="/icons.svg#icon-metamask"></use>
              </svg>

              {chain ?
                <p className="normal-case text-center dark:text-white">
                  Please change your network to one of the supported: {Object.values(chain).join(', ')}
                </p>
                : ''}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NetworkModal;