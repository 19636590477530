import {createSlice} from '@reduxjs/toolkit';
import {HYDRATE} from 'next-redux-wrapper';

export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    data: {}
  },
  reducers: {
    set: (state, action) =>
    {
      state.data = action.payload;
    }
  },
  extraReducers: {
    [HYDRATE]: (state, action) =>
    {
      return {
        ...state,
        ...action.payload.profile
      };
    }
  }
});

// Action creators are generated for each case reducer function
export const {set} = profileSlice.actions;

export default profileSlice.reducer;