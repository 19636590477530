import {useRef} from 'react';

import {ThemeProvider} from 'next-themes';
import {useRouter} from 'next/router';
import Script from 'next/script'

import {MetaMaskProvider} from 'metamask-react';

import {Provider} from 'react-redux';
import {wrapper} from '../redux/store';

import Layout from '../components/layout';
import Meta from '../components/Meta';
import UserContext from '../components/UserContext';

import '../styles/globals.css';

function App({Component, ...rest})
{
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({scrollPos: 0});

  const {store, props} = wrapper.useWrappedStore(rest);

  return (
    <>
      <Meta title="M3 Games"/>

      {process.env.NODE_ENV === 'production' ?
        <Script id="matomo">
          {`var _paq = window._paq = window._paq || [];
          _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
          _paq.push(["setCookieDomain", "*.m3.games"]);
          _paq.push(['trackPageView']);
          _paq.push(['enableLinkTracking']);
          (function() {
          var u="https://url.openpush.io/my/";
          _paq.push(['setTrackerUrl', u+'my.php']);
          _paq.push(['setSiteId', '6']);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src=u+'my.js'; s.parentNode.insertBefore(g,s);
          })();`}
        </Script>
        : ''}

      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
          <MetaMaskProvider>
            <UserContext.Provider value={{scrollRef: scrollRef}}>
              {pid === '/login' ? (
                <Component {...props.pageProps} />
              ) : (
                <Layout>
                  <Component {...props.pageProps} />
                </Layout>
              )}
            </UserContext.Provider>
          </MetaMaskProvider>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default App;