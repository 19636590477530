// noinspection JSUnresolvedVariable

import React, {useState} from 'react';

import Link from 'next/link';

import {ethers} from 'ethers';

import {useDispatch, useSelector} from 'react-redux';
import {withdrawModalShow} from '../../redux/counterSlice';

import UserId from '../userId';

import * as Request from '../../helpers/request';

export const Profile = () =>
{
  const [profile_show, setProfileShow] = useState(false);

  const profile = useSelector((state) => state.profile.data);
  const dispatch = useDispatch();

  if (Object.keys(profile).length)
  {
    return (
      <div className="js-nav-dropdown group-dropdown relative">
        <button
          className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          onMouseEnter={() => setProfileShow(true)}
          onMouseLeave={() => setProfileShow(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z"></path>
          </svg>
        </button>

        <div
          className={
            profile_show
              ? 'dropdown-menu dark:bg-jacarta-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl show lg:visible lg:opacity-100'
              : 'dropdown-menu dark:bg-jacarta-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0'
          }
          onMouseEnter={() => setProfileShow(true)}
          onMouseLeave={() => setProfileShow(false)}
        >
          <UserId
            classes="js-copy-clipboard font-display text-jacarta-700 my-4 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white"
            userId={profile.address}
            shortId={true}
          />

          <div className="dark:border-jacarta-600 border-jacarta-100 mx-5 mb-6 rounded-lg border p-4">
            <span className="dark:text-jacarta-200 text-sm font-medium tracking-tight">Balance</span>
            <div className="flex items-center">
              <svg className="icon icon-ETH -ml-1 mr-1 h-[1.125rem] w-[1.125rem]">
                <use xlinkHref="/icons.svg#icon-USD"></use>
              </svg>
              <span className="text-green text-lg font-bold">{Number(profile.balance)}</span>
            </div>
          </div>

          <Link href="/profile/token" className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              height="24"
              width="24"
              className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white"
            >
              <path d="m30 24v2.5c0 1.379-1.122 2.5-2.5 2.5h-21.5c-2.206 0-4-1.794-4-4 0 0 0-16.985 0-17 0-2.206 1.794-4 4-4h18.5c.829 0 1.5.672 1.5 1.5s-.671 1.5-1.5 1.5h-18.5c-.551 0-1 .448-1 1s.449 1 1 1h21.5c1.378 0 2.5 1.121 2.5 2.5v2.5h-5c-2.757 0-5 2.243-5 5s2.243 5 5 5z"/>
              <path d="m30 16v6h-5c-1.657 0-3-1.343-3-3s1.343-3 3-3z"/>
            </svg>

            <span className="font-display text-jacarta-700 text-sm dark:text-white">My tokens</span>
          </Link>

          <div
            className="cursor-pointer dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors"
            onClick={() => dispatch(withdrawModalShow())}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              height="24"
              width="24"
              className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white"
            >
              <path d="m21 6c0 2.85-4.3 5-10 5s-10-2.15-10-5 4.3-5 10-5 10 2.15 10 5zm-8 15c-4.25 0-7.88-1.1-10-2.95.06 2.83 4.33 4.95 10 4.95s9.91-2.11 10-4.91c-2.09 1.81-5.63 2.91-10 2.91zm-2-4a18.74 18.74 0 0 1 -7.51-1.4c1.28 2.02 4.94 3.4 9.51 3.4 5.7 0 10-2.15 10-5a3 3 0 0 0 -.57-1.72c-1.49 2.86-5.8 4.72-11.43 4.72zm1-4h-1-1c-3.92-.15-7.11-1.22-9-2.89.09 2.78 4.36 4.89 10 4.89s9.91-2.11 10-4.91c-1.93 1.67-5.12 2.74-9 2.91z"/>
            </svg>

            <span className="font-display text-jacarta-700 text-sm dark:text-white">Withdraw savings</span>
          </div>
        </div>
      </div>
    );
  }
}

export const login = async (account) =>
{
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
  const signer = provider.getSigner();

  let result = await Request.GET('/user/nonce', {address: account});

  if (result.status === 200)
  {
    try
    {
      const {status} = await Request.POST('/user/login', {address: account, sign: await signer.signMessage(result.body)});
      return status === 200;
    }
    catch (error) {}
  }

  return false;
}

export const get = (network_id) =>
{
  return Request.GET(`/network/${network_id}/user/profile`);
}

export const logout = () =>
{
  Request.POST(`/user/logout`).then(() => window.location.reload());
}

export default Profile;