// noinspection JSUnresolvedVariable

import {ethers} from 'ethers';

import mainnet from './0x1.json';
import polygon from './0x89.json';

export const CHAINS = {
  '0x1': {
    contract_address: process.env.NEXT_PUBLIC_MAINNET_CONTRACT_ADDRESS,
    contract_abi: mainnet.abi,
    uniswap_url: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
    rpc_url: process.env.MAINNET_RPC_PROVIDER,
    symbol: 'ETH'
  },
  '0x5': {
    contract_address: process.env.NEXT_PUBLIC_MAINNET_CONTRACT_ADDRESS,
    contract_abi: mainnet.abi,
    uniswap_url: 'https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3',
    rpc_url: process.env.MAINNET_RPC_PROVIDER,
    symbol: 'ETH'
  },
  '0x89': {
    contract_address: process.env.NEXT_PUBLIC_POLYGON_CONTRACT_ADDRESS,
    contract_abi: polygon.abi,
    uniswap_url: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap-v3-polygon',
    rpc_url: process.env.POLYGON_RPC_PROVIDER,
    symbol: 'MATIC'
  }
};

export const get = (account, chain_id) =>
{
  const provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
  return [provider, new ethers.Contract(getContractAddress(chain_id), getContractABI(chain_id), provider.getSigner(account))];
}

export const getRPC = (chain_id) =>
{
  const provider = new ethers.providers.JsonRpcProvider(getRPCAddress(chain_id));
  return new ethers.Contract(getContractAddress(chain_id), getContractABI(chain_id), provider);
}

export const getContractAddress = (chain_id) =>
{
  return CHAINS[chain_id] ? CHAINS[chain_id].contract_address : '';
}

export const getContractABI = (chain_id) =>
{
  return CHAINS[chain_id] ? CHAINS[chain_id].contract_abi : {};
}

export const getRPCAddress = (chain_id) =>
{
  return CHAINS[chain_id] ? CHAINS[chain_id].rpc_url : '';
}

export const getRate = async (pool, chain_id) =>
{
  if (!CHAINS[chain_id])
  {
    return;
  }

  const result = await fetch(CHAINS[chain_id].uniswap_url, {
    method: 'POST',
    headers: new Headers({'content-type': 'application/json'}),
    body: `{"query":"{\\n  pool(id: \\"${pool}\\") {\\n    token0Price\\n    token1Price\\n  }\\n}"}`
  });

  return (await result.json()).data.pool;
}

export const getSymbol = (chain_id) =>
{
  return CHAINS[chain_id] ? CHAINS[chain_id].symbol : '';
}

export const getIPFSUri = (uri) =>
{
  return uri.replace('ipfs://', 'https://ipfs.io/ipfs/');
}

export const isRevealed = (created, reveal_time) =>
{
  return Math.floor(new Date(created).getTime() / 1000) + Number(reveal_time) < Math.floor(new Date().getTime() / 1000);
}