import React from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {successModalHide} from '../../redux/counterSlice';

const SuccessModal = () =>
{
  const successModal = useSelector((state) => state.counter.successModal);
  const dispatch = useDispatch();

  return (
    <div className="relative z-100">
      <div className={successModal ? 'block modal fade show ' : 'modal fade hidden'}>
        <div className="modal-dialog max-w-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Transaction successful</h5>
              <button type="button" className="btn-close" onClick={() => dispatch(successModalHide())}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>

            <div className="modal-body p-6 text-center">
              <svg className="icon icon-metamask mb-4 inline-block h-8 w-8">
                <use xlinkHref="/icons.svg#icon-metamask"></use>
              </svg>

              <p className="normal-case text-center dark:text-white">
                You have successfully purchased a token!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;