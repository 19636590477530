// noinspection JSUnresolvedVariable

import React, {useEffect, useState} from 'react';

import Link from 'next/link';
import {useRouter} from 'next/router';

import {useSelector, useDispatch} from 'react-redux';
import {closeMobileMenu} from '../redux/counterSlice';

import {MetamaskButton, MetamaskIcon} from './metamask/Metamask';

import Profile from './profile/profile';
import * as Request from '../helpers/request';

const NavigationMobile = ({theme}) =>
{
  const [navItemValue, setNavItemValue] = useState(1);
  const [navText, setNavText] = useState('');
  const [owner, setOwner] = useState('');

  const dispatch = useDispatch();
  const router = useRouter();

  const profile = useSelector((state) => state.profile.data);
  const {mobile_menu} = useSelector((state) => state.counter);

  const handleItemDropdown = (e) =>
  {
    const target = e.target.closest('li');

    if (!target.classList.contains('show'))
    {
      target.classList.add('show');
    }
    else
    {
      target.classList.remove('show');
    }
  };

  const getOwner = () =>
  {
    Request.GET(`/network/${profile.network.id}/setting/owner`)
      .then((result) => result.status !== 200 || setOwner(result.body.value));
  }

  useEffect(() =>
  {
    !Object.keys(profile).length || getOwner();
  }, [profile]);

  useEffect(() =>
  {
    window.addEventListener('resize', () =>
    {
      window.innerWidth < 1024 || dispatch(closeMobileMenu());
    });

    if (router.asPath.indexOf('/author/project-group') === 0)
    {
      setNavText('author');
      setNavItemValue(2);
    }
    else if (router.asPath.indexOf('/author/project') === 0)
    {
      setNavText('author');
      setNavItemValue(3);
    }
    else
    {
      setNavText('');
      setNavItemValue(1);
    }
  }, [dispatch, navItemValue, router]);

  const author_data = [
    {
      id: 2,
      text: 'Project group',
      url: '/author/project-group',
      onClick: () => dispatch(closeMobileMenu())
    },
    {
      id: 3,
      text: 'Project',
      url: '/author/project',
      onClick: () => dispatch(closeMobileMenu())
    },
    {
      id: 4,
      text: 'Transfer ownership',
      url: '/author/ownership',
      onClick: () => dispatch(closeMobileMenu()),
      owner: true
    }
  ];

  return (
    <div
      className={
        mobile_menu
          ? 'js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent nav-menu--is-open'
          : 'js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent'
      }
    >
      <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
        <Link href="/">
          <img
            src="/images/logo.png"
            className="max-h-12 md:max-h-14 h-auto"
            alt="M3"
          />
        </Link>

        <button
          className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          onClick={() => dispatch(closeMobileMenu())}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"></path>
          </svg>
        </button>
      </div>

      <nav className="navbar w-full mt-24 lg:mt-0">
        <ul className="flex flex-col lg:flex-row">
          {Object.keys(profile).length && owner.toLowerCase() === profile.address.toLowerCase() ?
            <li className="js-nav-dropdown group relative">
              <button
                className={
                  router.asPath === '/home/home_3'
                    ? 'dropdown-toggle font-display hover:text-accent focus:text-accent flex items-center justify-between py-3.5 text-base text-jacarta-700 dark:lg:text-jacarta-700 lg:text-white lg:px-5 w-full'
                    : 'dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full'
                }
                onClick={(e) => handleItemDropdown(e)}
              >
                <span className={navText === 'author' ? 'text-accent' : ''}>Author</span>

                <i className="lg:hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    className="h-4 w-4 dark:fill-white"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z"></path>
                  </svg>
                </i>
              </button>

              <ul className="dropdown-menu dark:bg-jacarta-800 left-0 top-[85%] z-10 hidden min-w-[200px] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 lg:invisible lg:absolute lg:grid lg:translate-y-4 lg:py-4 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative">
                {author_data.map(({id, text, url, label = false, only_owner = false, onClick}) =>
                  !only_owner || owner === profile.address  ?
                    <li key={id}>
                      <Link
                        href={url}
                        className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors"
                        onClick={onClick}
                      >
                        <span className={navItemValue === id ? 'font-display text-accent text-sm' : 'font-display text-jacarta-700 text-sm dark:text-white'}>{text}</span>
                        {label && <span className="rounded bg-green py-1 px-2 text-xs font-bold uppercase leading-none text-white ml-4">new</span>}
                      </Link>
                    </li>
                    : ''
                )}
              </ul>
            </li>
            : ''}
        </ul>
      </nav>

      <div className="mt-16 w-full lg:hidden">
        <MetamaskButton/>
      </div>

      <div className="ml-8 hidden lg:flex xl:ml-12">
        <MetamaskIcon prop={router}/>

        <Profile/>

        <button
          className="border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent js-dark-mode-trigger ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
          onClick={() => theme()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 dark-mode-light h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:hidden"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M11.38 2.019a7.5 7.5 0 1 0 10.6 10.6C21.662 17.854 17.316 22 12.001 22 6.477 22 2 17.523 2 12c0-5.315 4.146-9.661 9.38-9.981z"></path>
          </svg>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            className="fill-jacarta-700 dark-mode-dark hidden h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:block dark:fill-white"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M12 18a6 6 0 1 1 0-12 6 6 0 0 1 0 12zM11 1h2v3h-2V1zm0 19h2v3h-2v-3zM3.515 4.929l1.414-1.414L7.05 5.636 5.636 7.05 3.515 4.93zM16.95 18.364l1.414-1.414 2.121 2.121-1.414 1.414-2.121-2.121zm2.121-14.85l1.414 1.415-2.121 2.121-1.414-1.414 2.121-2.121zM5.636 16.95l1.414 1.414-2.121 2.121-1.414-1.414 2.121-2.121zM23 11v2h-3v-2h3zM4 11v2H1v-2h3z"></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default NavigationMobile;