// noinspection JSUnresolvedVariable

import React from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {set as setProgress} from '../../redux/progressSlice';

import LoadingBar from 'react-top-loading-bar';

const ProgressBar = () =>
{
  const dispatch = useDispatch();
  const progress = useSelector((state) => state.progress.value);

  return <LoadingBar color="#8358ff" progress={progress} onLoaderFinished={() => dispatch(setProgress(0))}/>;
}

export default ProgressBar;