import React, {useEffect} from 'react';

import {useMetaMask} from 'metamask-react';

import {useDispatch, useSelector} from 'react-redux';
import {set as setProfile} from '../redux/profileSlice';
import {set as setRate} from '../redux/rateSlice';
import {set as setChain} from '../redux/chainSlice';
import {networkModalHide, networkModalShow} from '../redux/counterSlice';

import Navbar from './navbar';
import Footer from './footer';
import NetworkModal from './modal/network-modal';
import BuyModel from './modal/buy-modal';
import SuccessModel from './modal/success-modal';
import WithdrawModal from './modal/withdraw-modal';
import ProgressBar from './progress-bar/progress-bar';

import * as Profile from './profile/profile';
import * as Request from '../helpers/request';
import {getRate} from '../helpers/contract';

export default function Layout({children})
{
  const {account, chainId, status, switchChain} = useMetaMask()

  const dispatch = useDispatch();
  const chain = useSelector((state) => state.chain.value);

  // Check affiliate
  if (typeof window !== "undefined")
  {
    const get_params = new URLSearchParams(new URL(window.location.href).searchParams);

    if (get_params.get('affiliate'))
    {
      localStorage.setItem('affiliate', JSON.stringify({value: get_params.get('affiliate'), expire: new Date().getTime() / 1000 + 172800}));
    }
  }

  const checkLogin = async () =>
  {
    let result = await Profile.get(parseInt(chainId, 16));

    if (result.status !== 200)
    {
      if (await Profile.login(account))
      {
        const result = await Profile.get(parseInt(chainId, 16));
        result.status !== 200 || dispatch(setProfile(result.body));
      }
    }
    else if (account.toLowerCase() !== result.body.address.toLowerCase())
    {
      Profile.logout();
    }
    else
    {
      dispatch(setProfile(result.body));
    }
  }

  const getNetworks = () =>
  {
    Request.GET(`/network`)
      .then((result) =>
      {
        if (result.status === 200)
        {
          const data = {};
          result.body.data.map((item) => data[`0x${Number(item.id).toString(16)}`] = item.title);
          dispatch(setChain(data));
        }
      });
  }

  const switchNetwork = () =>
  {
    if (!chain[chainId])
    {
      dispatch(networkModalShow());
      switchChain(Object.keys(chain)[0]);
      return;
    }

    dispatch(networkModalHide());
  }

  useEffect(() =>
  {
    getRate(process.env.NEXT_PUBLIC_ETH_USDC_POOL, '0x1').then((result) =>
      dispatch(setRate({chain_id: process.env.NEXT_PUBLIC_ETH_USDC_CHAIN, value: result.token1Price})));

    getRate(process.env.NEXT_PUBLIC_MATIC_USDC_POOL, '0x89').then((result) =>
      dispatch(setRate({chain_id: process.env.NEXT_PUBLIC_MATIC_USDC_CHAIN, value: result.token0Price})));

    getNetworks();
  }, []);

  useEffect(() =>
  {
    if (status === 'connected' && chain)
    {
      switchNetwork();
      !chain[chainId] || checkLogin();
    }
  }, [chainId, account, chain]);

  return (
    <>
      <ProgressBar/>
      <Navbar/>
      <NetworkModal/>
      <BuyModel/>
      <SuccessModel/>
      <WithdrawModal/>
      <main>{children}</main>
      <Footer/>
    </>
  );
}